import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Import utilities
import { usePip, usePlayerAds } from 'components/utilities';

const usePlayer = ({ type, clearSelectedProgram, playerOnCancel }) => {
	const [isFullScreen, setFullScreen] = useState(false);
	const [playerParentElement, setPlayerParentElement] = useState(null);
	const [isAutoPlay, setIsAutoPlay] = useState(false);

	const { isError } = useSelector((state) => state[type]?.error);

	// Set use pip
	const { exitPip } = usePip();

	const handleSetIsAutoPlay = () => setIsAutoPlay(true);

	const { isAdsLoading, adsSettingsUrl } = usePlayerAds({
		isChannelPlayer: true
	});

	useEffect(() => {
		return () => {
			// Exit pip
			exitPip();
			// Dispatch an playerOnCancel action in player folder
			playerOnCancel(type);
			// Dispatch anplayerOnCancel, clearSelectedProgram action in slected_program folder
			clearSelectedProgram();
		};
		// eslint-disable-next-line
	}, []);

	return {
		isFullScreen,
		playerParentElement,
		isAutoPlay,
		isError,
		isAdsLoading,
		setFullScreen,
		setPlayerParentElement,
		setIsAutoPlay: handleSetIsAutoPlay,
		adsSettingsUrl
	};
};

export default usePlayer;
