import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	deleteVideoSession,
	setPlayerPlaybackError,
	updateWatchingInProgress,
	updateVideoSession,
	setPlayerReady
} from 'store/actions';
import throttle from 'lodash/throttle';

// import shaka-player
import shaka from 'shaka-player/dist/shaka-player.ui.js';

// Import helpers
import {
	INITIAL_STATE_PLAYER_VOD,
	TIME_THROTTLE,
	calculateVideoIntervalTime,
	getSegmentsData,
	getUserConfigVolume,
	setUserConfigVolume
} from '../helpers';
import {
	AUTO_QUALITY_ITEM,
	playerDeleteSyncRequest,
	SHAKA_PLAYER_ERRORS
} from 'helpers/player';

// styles and helpers
import {
	VideoContainer,
	ShakaVideo,
	fetchPlaylistData,
	renderSubtitlesForSafari,
	removeShakaPlayerListeners,
	playerInitialization,
	setSupportError,
	setTrackingEvent,
	sendPlayerEvents,
	sendPreviousSessionTracking
} from '../shakaPlayer';

// Variables
import {
	EVENTS_NAMES,
	SECURITY_TYPES,
	STRIPES_URL,
	TRACKING_EVENTS
} from 'helpers/variables';

const { TIME_UPDATE, BEFORE_UNLOAD } = EVENTS_NAMES;

// Create context
export const ShakaPlayerContext = createContext();

const { SHAKA_PLAYER_ERROR_MESSAGE } = SHAKA_PLAYER_ERRORS;

const { DRM } = SECURITY_TYPES;

class ShakaPlayerProvider extends React.Component {
	_isMounted = false;
	isPlayerReady = false;
	intervalUpdateWatchingProgress = null;
	intervalVideoSession = null;
	trackingInterval = null;
	adsInterval = null;
	streamStartDate = null;

	constructor(props) {
		super(props);
		this.state = INITIAL_STATE_PLAYER_VOD;
		this.timeThrottle = throttle(
			(time) => this.handleSetCurrentTime(time),
			TIME_THROTTLE
		);

		//Creating reference to store video component and video container
		this.videoComponentRef = React.createRef();
		this.videoContainerRef = React.createRef();

		// Shaka media
		this.video = null;
		this.videoContainer = null;
		this.player = null;
		this.castProxy = null;

		this.playerTarget = null;
		this.videoTarget = null;
	}

	async componentDidMount() {
		const {
			playlist,
			isError,
			videoSessionId,
			isSiedebarVisible,
			configuration: { movieEndMargin },
			adsSettingsUrl,
			vodTimeIntervalAd
		} = this.props;

		this._isMounted = true;
		this.setState({ isSiedebarVisible, movieEndMargin });

		// send previous session tracking data
		sendPreviousSessionTracking();

		// Delete video session
		window.addEventListener(BEFORE_UNLOAD, this.beforeunload, false);

		const playlistData = await fetchPlaylistData(playlist, DRM);

		if (videoSessionId && this._isMounted && !this.isPlayerReady && !isError) {
			// Install built-in polyfills to patch browser incompatibilities.
			shaka.polyfill.installAll();

			//Getting reference to video and video container on DOM
			this.video = this.videoComponentRef.current;
			this.videoContainer = this.videoContainerRef.current;

			shaka.Player.isBrowserSupported()
				? playerInitialization.call(this, [
						shaka,
						playlistData,
						adsSettingsUrl,
						vodTimeIntervalAd
				  ])
				: setSupportError.call(this);
		} else if (isError && videoSessionId) {
			// If there is an error and video session is not equal to null
			// Turn on buffering
			this._isMounted && this.setState({ buffering: false });
		} else if (isError && !videoSessionId) {
			// If there is an error and video session is equal to null
			// Stop player
			this.playerInstanceDestroy();
			// Turn on buffering,Set player ready
			this._isMounted && this.setState({ buffering: false, isReady: false });
		}
	}

	componentWillUnmount() {
		// Update watching progress time
		this.handleFetchWatchingProgress();

		this._isMounted = false;

		// Clear intervals
		clearInterval(this.intervalVideoSession);
		clearInterval(this.intervalUpdateWatchingProgress);
		clearInterval(this.trackingInterval);
		clearInterval(this.adsInterval);

		const { isError, videoSessionId, type, deleteVideoSession } = this.props;
		// Check if player is not undefined / null, if player container exists, if is not any API errors

		if (videoSessionId) {
			// Dispatch an deleteVideoSession action in player folder
			deleteVideoSession({ type });
		}

		if (!isError && this.player && this.isPlayerReady) {
			// send tracking events
			setTrackingEvent.call(this, TRACKING_EVENTS.CLOSED);
			sendPlayerEvents.call(this);
			// remove event listeners
			removeShakaPlayerListeners.call(this);
			// Destroy the player
			this.playerInstanceDestroy();
			this.isPlayerReady = false;
		}
	}

	handleFetchWatchingProgress = (currentProgress = null) => {
		const { isPreview } = this.props;
		const { isReady } = this.state;
		const isAvaialbe =
			this._isMounted && isReady && this.videoTarget?.currentTime;

		if (isAvaialbe && !isPreview) {
			const currentTime = currentProgress || this.videoTarget.currentTime;
			const time = Math.floor(currentTime);
			this.props.updateWatchingInProgress({
				productUuid: this.props.productID,
				time
			});
		}
	};

	beforeunload = () => {
		const { videoSessionId } = this.props;
		// Destroy the player
		this.playerInstanceDestroy();
		// delete session
		playerDeleteSyncRequest(videoSessionId);
	};

	setFullScreen = () => {
		if (!document.fullscreenElement) {
			this.videoContainer.requestFullscreen();
			this.setState({ isFullScreen: true });
		} else {
			document.exitFullscreen();
			this.setState({ isFullScreen: false });
		}
	};

	play = () => {
		this.videoTarget.play();
		this.setState({ isPaused: false });
	};

	pause = () => {
		this.videoTarget.pause();
		this.setState({ isPaused: true });
	};

	updateTime = (time) => {
		this.videoTarget.currentTime = time;
		setTrackingEvent.call(this, TRACKING_EVENTS.SEEKING);
	};

	updateSeekTime = (next = true) => {
		const { seekSec } = this.state;
		const currentTime = this.videoTarget.currentTime;

		next
			? this.updateTime(currentTime + seekSec)
			: this.updateTime(currentTime - seekSec);
	};

	setLanguage = (lang) => {
		const { profiles, selectedProfileBandwidth } = this.state;
		this.setState({ selectedLanguage: lang });
		this.playerTarget.selectAudioLanguage(lang);

		// set quality profile after audio language change
		const videoProfile = profiles[lang].find(
			({ bandwidth }) => bandwidth === selectedProfileBandwidth
		);

		if (videoProfile) {
			const { id, bandwidth } = videoProfile;
			this.setProfile({ id, bandwidth, lang });
		}
	};

	// Set subtitle
	setSubtitle = (value) => {
		if (value === 'disabled') {
			this.playerTarget.setTextTrackVisibility(false);
		} else {
			this.playerTarget.setTextTrackVisibility(true);
			this.playerTarget.selectTextLanguage(value);
		}

		this.setState({ selectedSubtitle: value });
	};

	// Set profile
	setProfile = ({ id, bandwidth, lang }) => {
		const { profiles, selectedLanguage } = this.state;
		const clearBuffer = true;
		let language = selectedLanguage;

		// after change language 'selectedLanguage' has an old value
		// if 'lang' param exist set its value to language variable
		if (lang) {
			language = lang;
		}

		if (id !== AUTO_QUALITY_ITEM.id) {
			this.playerTarget.configure({ abr: { enabled: false } });
			const variant = profiles[language].find((variant) => variant.id === id);
			this.playerTarget.selectVariantTrack(variant, clearBuffer);
		} else {
			this.playerTarget.configure({ abr: { enabled: true } });
		}

		setTrackingEvent.call(this, TRACKING_EVENTS.TRACK_CHANGED);

		this.setState({
			selectedProfile: id,
			selectedProfileBandwidth: bandwidth
		});
	};

	// Mute or unmute player
	mute = () => {
		const { isMuted, volume } = this.state;

		const isVideoMuted = parseInt(volume) ? !isMuted : true;
		const options = { isMuted: isVideoMuted, volume };

		this.setState(options);

		setUserConfigVolume(options);
	};

	// Set current volume
	setVolume = (volume, isUserConfigMuted = false) => {
		this.videoTarget.volume = volume / 100;

		const isMuted = isUserConfigMuted || parseInt(volume) === 0;
		const options = { volume, isMuted };

		this.setState(options);

		setUserConfigVolume(options);
	};

	onReady = () => {
		const {
			type,
			setPlayerReady,
			configuration: { videoSession }
		} = this.props;

		// Set player ready
		this.isPlayerReady = true;
		this.setState({ isReady: true });
		// Dispatch an setPlayerReady action in player folder
		setPlayerReady({ type });

		// Set watching in progress interval
		const watchingIntervalTime = parseInt(
			process.env.REACT_APP_WATCHING_IN_PROGRESS
		);
		this.intervalUpdateWatchingProgress = setInterval(
			this.handleFetchWatchingProgress,
			watchingIntervalTime
		);
		// Set video session interval
		const videoSessionIntervalTime = calculateVideoIntervalTime(videoSession);
		this.intervalVideoSession = setInterval(
			this.handleUpdateVideoSession,
			videoSessionIntervalTime
		);

		// Set player volume
		const { volume, isMuted: isUserConfigMuted } = getUserConfigVolume();

		this.setVolume(volume, isUserConfigMuted);
	};

	handleUpdateVideoSession = () => {
		const { videoSessionId, type, productID, updateVideoSession } = this.props;

		updateVideoSession({ videoSessionId, productID, type }, () => {
			// When is an api error
			// Destroy the player
			this.playerInstanceDestroy();
			// Turn on buffering
			this.setState({ buffering: false, isReady: false });
			// Clear intervals
			clearInterval(this.intervalVideoSession);
			clearInterval(this.intervalUpdateWatchingProgress);
			clearInterval(this.trackingInterval);
			clearInterval(this.adsInterval);
		});
	};

	playerInstanceDestroy = () => {
		this.player && this.player.destroy();
		this.castProxy && this.castProxy.destroy(true);
	};

	onPlay = () => {
		this.setState({ isPaused: false });
		setTrackingEvent.call(this, TRACKING_EVENTS.PLAYING);
	};

	onPause = () => {
		this.setState({ isPaused: true });
		setTrackingEvent.call(this, TRACKING_EVENTS.PAUSED);
	};

	onBufferStateChange = () => {
		const buffering = this.playerTarget.isBuffering();
		this.setState({ buffering });
		buffering && setTrackingEvent.call(this, TRACKING_EVENTS.BUFFERING);
	};

	onEnded = () => {
		this.setState({ isEnded: true, isPaused: true });
		setTrackingEvent.call(this, TRACKING_EVENTS.COMPLETE);
	};

	onAutoQualityChange = () =>
		setTrackingEvent.call(this, TRACKING_EVENTS.TRACK_CHANGED);

	onErrorEvent = (event) => {
		// Extract the shaka.util.Error object from the event.
		this.onError(event.detail);
	};

	onError = (error) => {
		const isOnline = navigator.onLine;

		if (this && this.props) {
			const { setPlayerPlaybackError, type } = this.props;

			setTrackingEvent.call(this, TRACKING_EVENTS.ERROR);

			// Catch only known errors which should be handled in UI
			if (error && error.code && isOnline) {
				this.setState({ isReady: false, buffering: false });
				// Destroy the player
				if (this.player) {
					this.playerInstanceDestroy();
				}
				// Set error message
				error.message = SHAKA_PLAYER_ERROR_MESSAGE;
				// Dispatch an setPlayerPlaybackError action in player folder
				setPlayerPlaybackError(error, type);
				// Set player ready / Turn on buffering
				// Clear intervals
				clearInterval(this.intervalVideoSession);
				clearInterval(this.intervalUpdateWatchingProgress);
				clearInterval(this.trackingInterval);
				clearInterval(this.adsInterval);
			}
		}
	};

	onFullScreenChange = () => {
		const isFullScreen = !!document.fullscreenElement;
		this.setState({ isFullScreen });
	};

	getDuration = () => {
		const { isReady, duration } = this.state;

		const isAvailable = this._isMounted && isReady && this.video && duration;
		return isAvailable ? this.videoTarget.duration : null;
	};

	handleSetCurrentTime = (time) => {
		const { isReady } = this.state;
		const isAvaialbe = this._isMounted && this.video && isReady;
		// Get duration
		if (isAvaialbe) {
			const duration = this.getDuration();
			if (duration && this.state.duration !== duration) {
				this.setState({ duration });
			}

			// Set end margin action
			const { endMarginAction, movieEndMargin } = this.state;
			const endMargin = duration - movieEndMargin;

			if (duration && time > endMargin && !endMarginAction) {
				this.setState({ endMarginAction: true });
			} else if (duration && time < endMargin && endMarginAction) {
				this.setState({ endMarginAction: false });
			}
		}
	};

	currentTimeProgress = (callback) =>
		this.videoTarget.addEventListener(TIME_UPDATE, () =>
			callback(this.videoTarget.currentTime)
		);

	restart = () => {
		this.video.currentTime = 0;
		this.setState({ isEnded: false });
	};

	onTimeUpdate = () => this.timeThrottle(this.videoTarget.currentTime);

	setEndMargin = (value = true) => {
		this.setState({ endMarginAction: value });
	};

	checkFirstStripe = () => {
		const {
			configuration: { videoId },
			productID
		} = this.props;

		const url = `${STRIPES_URL}/${productID}/${videoId}/stripes/stripe_0.jpg`;
		const firstStripe = new Image();

		firstStripe.onerror = () => this.setState({ isPreviewAvailable: false });
		firstStripe.onload = () => this.setState({ isPreviewAvailable: true });
		firstStripe.src = url;
	};

	onLoadedData = () => {
		const { continueWatchingTime } = this.props;
		const hasProgressHistory = continueWatchingTime >= 4;
		// force play() event if data is loaded to prevent video freeze
		!hasProgressHistory && this.play();
	};

	cast = () => {
		const { isCasting } = this.state;
		isCasting ? this.castProxy.suggestDisconnect() : this.castProxy.cast();
	};

	onCastStatusChanged = ({ target }) => {
		const receiverName = target.receiverName();
		const isCasting = target.isCasting();
		const isCastAvailable = target.canCast();
		this.setProfile(AUTO_QUALITY_ITEM);
		this.setState({ receiverName, isCasting, isCastAvailable });
	};

	render() {
		const {
			closePreview,
			isPreview,
			isSafari,
			isFocus,
			children,
			resetFocus,
			productID,
			configuration: { videoId, segments },
			isRtl
		} = this.props;
		const { isReady, isMuted } = this.state;

		const segmentsData = getSegmentsData({ segments });

		const currentTime = this.video ? this.video.currentTime : 0;

		return (
			<ShakaPlayerContext.Provider
				value={{
					...this.state,
					isPreview,
					isSafari,
					closePreview,
					setVolume: this.setVolume,
					setSubtitle: this.setSubtitle,
					setLanguage: this.setLanguage,
					setProfile: this.setProfile,
					mute: this.mute,
					currentTime,
					updateTime: this.updateTime,
					updateSeekTime: this.updateSeekTime,
					setFullScreen: this.setFullScreen,
					play: this.play,
					pause: this.pause,
					restart: this.restart,
					currentTimeProgress: this.currentTimeProgress,
					setEndMargin: this.setEndMargin,
					resetFocus,
					productID,
					videoId,
					video: this.video,
					segmentsData,
					cast: this.cast,
					castProxy: this.castProxy,
					videoTarget: this.videoTarget
				}}
			>
				<VideoContainer
					className="video-container"
					ref={this.videoContainerRef}
					isReady={isReady}
					isFocus={isFocus}
					isRtl={isRtl}
				>
					<ShakaVideo
						className="shaka-video"
						id="shaka-video"
						ref={this.videoComponentRef}
						autoPlay={true}
						muted={isMuted}
						onLoadedData={this.onLoadedData}
					>
						{isSafari && renderSubtitlesForSafari.call(this)}
					</ShakaVideo>
					{children}
				</VideoContainer>
			</ShakaPlayerContext.Provider>
		);
	}
}

ShakaPlayerProvider.defaultProps = {
	isSiedebarVisible: false,
	isSafari: false
};

ShakaPlayerProvider.propTypes = {
	isSiedebarVisible: PropTypes.bool,
	playlist: PropTypes.string.isRequired,
	isError: PropTypes.bool.isRequired,
	videoSessionId: PropTypes.string,
	deleteVideoSession: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
	productID: PropTypes.string.isRequired,
	configuration: PropTypes.object.isRequired,
	closePreview: PropTypes.func.isRequired,
	isPreview: PropTypes.bool.isRequired,
	isSafari: PropTypes.bool.isRequired,
	isFocus: PropTypes.bool.isRequired,
	resetFocus: PropTypes.func.isRequired,
	updateWatchingInProgress: PropTypes.func.isRequired,
	adsSettingsUrl: PropTypes.string,
	vodTimeIntervalAd: PropTypes.number
};

const mapStateToProps = (state, { type }) => {
	const { movieDetails, continue_watching } = state;

	const {
		playlist,
		configuration,
		error: { isError },
		videoSessionId
	} = state[type];

	const {
		direction: { isRtl }
	} = state;

	const { uuid: productID } = movieDetails.data;

	const continueWatchingTime = continue_watching.continueProgress?.time;

	return {
		playlist,
		productID,
		isError,
		configuration,
		videoSessionId,
		continueWatchingTime,
		isRtl
	};
};

export default connect(
	mapStateToProps,
	{
		deleteVideoSession,
		setPlayerPlaybackError,
		updateWatchingInProgress,
		updateVideoSession,
		setPlayerReady
	}
)(ShakaPlayerProvider);
