import React from 'react';
import ReactDOM from 'react-dom';

import 'babel-polyfill';
import 'whatwg-fetch';

// Import translations
import './i18n';

// Import vendors
import './vendors/vendor_styles';

// Import root component
import Root from './Root';

// if (process.env.NODE_ENV !== 'development') {
// 	console.log = () => {};
// }

ReactDOM.render(<Root />, document.getElementById('root'));

if ('serviceWorker' in navigator) {
	window.addEventListener('load', () => {
		navigator.serviceWorker
			.register('/sw-detect-hls-manifest.js')
			.then((registration) => {
				console.log(
					'Service Worker ( sw-detect-hls-manifest.js ) registered with scope:',
					registration.scope
				);
			})
			.catch((error) => {
				console.error('Service Worker registration failed:', error);
			});

		navigator.serviceWorker
			.register('/firebase-messaging-sw.js')
			.then(function(registration) {
				console.log(
					'Service Worker ( rebase-messaging-sw.js ) registered with scope',
					registration.scope
				);
			})
			.catch(function(err) {
				console.log('Service worker registration failed, error:', err);
			});
	});
}
